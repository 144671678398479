import uniqid from 'uniqid'
import GitHubIcon from '@material-ui/icons/GitHub'
import LaunchIcon from '@material-ui/icons/Launch'
import './ExperienceC.css'

const ExperienceContainer = ({ experience }) => (
	<div className='experience'>
		<div className='experience__header'>
		<h3>{experience.name}</h3>
			<h6>{experience.company}</h6>
			<p>{experience.duration}</p>
	  </div>
  
	  <div className='experience__description'>
		<span dangerouslySetInnerHTML={{ __html: experience.description }} />
	  </div>
  
	  {/* {experience.stack && (
		<ul className='experience__stack'>
		  {experience.stack.map((item) => (
			<li key={uniqid()} className='experience__stack-item'>
			  {item}
			</li>
		  ))}
		</ul>
	  )}
  
	  {experience.sourceCode && (
		<a
		  href={experience.sourceCode}
		  aria-label='source code'
		  className='link link--icon'
		>
		  <GitHubIcon />
		</a>
	  )}
  
	  {experience.livePreview && (
		<a
		  href={experience.livePreview}
		  aria-label='live preview'
		  className='link link--icon'
		>
		  <LaunchIcon />
		</a>
	  )} */}
	</div>
  );
  
  

export default ExperienceContainer
