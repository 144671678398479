const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://portfolio.simrantandon.live/',
  title: 'ST.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Simran Tandon',
  role: 'Full Stack Engineer',
  description:
		'My name is Simran. Having 1+ years of experience in Full Stack development and more enthusiasm for my work. Able to work independently, as a part of team and able to vaporize and grasp the new things. I always love to learning new things and share my knowledge with others.'
	,
  resume: 'https://drive.google.com/file/d/16yKKDAUOeb0WuJjchcRIqjw2aUUpW-Uc/view',
  social: {
    linkedin: 'https://www.linkedin.com/in/simran-tandon1/',
    github: 'https://github.com/simrantandon2801',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Invoice Generator',
    description:
      'Developed a web application enabling users to generate, send, and download receipts, quotations etc. through seamless email integration',
	  stack: ['MongoDB', 'Nodejs', 'React','Html','Css','Javascript','Express'],
    sourceCode: 'https://github.com',
    livePreview: 'https://invoice.simrantandon.live/landing',
  },
  {
    name: 'SocialoPedia',
    description:
      'Developed a website for posting and creating the post as well as everytime new quotes from api and pro feature to get verified tag',
	  stack: ['MongoDB', 'Nodejs', 'React','Redux','Javascript','Express'],
    sourceCode: 'https://github.com',
    livePreview: 'https://project2.simrantandon.live/',
  },
	{
		name: 'Tours & Travel Management',
		description:
			'Developed an System with admin panel for creating the tours from admin panel and show them on normal website and get tours to purchase it and show to them what tours they have purchased yet',
		stack: ['MongoDB', 'Nodejs', 'React','Html','Css','Javascript','Express'],
    sourceCode: 'https://github.com/simrantandon2801',
    livePreview: 'https://project3.simrantandon.live/',
	},
	{
		name: 'Freelance Company Website Template',
		description:
			'This is just a template for freelance website using reactjs and nodejs to get the orders regarding the services we are providing so they can get the orders on email as well',
		stack: ['MongoDB', 'Nodejs', 'React','Html','Css','Javascript','Express'],
    sourceCode: 'https://github.com/simrantandon2801',
    livePreview: 'https://simrantandon.live/',
	},
	{
		name: 'Javascript Minor Project',
		description:
			'This is my minor project which was doing for learning the concept of javascript and starting in this javascript one',
		stack: ['MongoDB', 'Nodejs', 'React','Html','Css','Javascript','Express'],
    sourceCode: 'https://github.com/simrantandon2801',
    livePreview: 'https://www.linkedin.com/in/simran-tandon1/',
	},
	{
		name: 'Portfolio',
		description:
			'This is  a project of showing my projects and skills which i learnt from last year and my personal project and information to demonstrate it.',
		stack: ['MongoDB', 'Nodejs', 'React','Css','Javascript','Express'],
    sourceCode: 'https://github.com/simrantandon2801',
    livePreview: 'https://portfolio.simrantandon.live/',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'React',
  'Redux',
  'SASS',
  'Material UI',
  'Git',
  'CI/CD',
  'Nodejs',
  'Github',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'simrantandon2801@mail.com',
}
const Experiences = [
	{
	  name: 'Full Stack Developer',
	  description: `
		Successfully built the project from scratch using the MERN stack, ensuring a scalable and maintainable codebase.<br> \n
		Developed and deployed a secure authentication system, allowing users to securely access our platform.<br>\n
		Implemented a mail system that automatically notifies users of their orders, ensuring a seamless and hassle-free transaction experience.<br>\n
		Integrated a payment gateway, providing users with a safe and convenient way to make transactions.<br>\n
		Built visually stunning and responsive web pages for our WordPress development efforts, while ensuring high-quality, reusable markup.<br>\n
		Integrated a new API into our project, allowing us to provide timely and relevant news to our users.<br>\n
		Created a robust and reliable system for fetching and displaying news articles`,
	  stack: ['MongoDB', 'Nodejs', 'React', 'Html', 'Css', 'Javascript', 'Express'],
	  sourceCode: 'https://github.com',
	  livePreview: 'https://github.com',
		duration: '1 year',
		company:'Hubhawks'// Add the duration field
	},
  ];
  

export { header, about, projects, skills, contact,Experiences }
