import uniqid from 'uniqid'
import { Experiences } from '../../portfolio'
import ExperienceContainer from './ExperienceContainer'
import './Experience.css'

const Experience = () => {
  if (!Experiences.length) return null

  return (
    <section id='experience' className='section experiences'>
      <h2 className='section__title'>Experience</h2>

      <div className='experience__grid'>
        {Experiences.map((experience) => (
          <ExperienceContainer key={uniqid()} experience={experience} />
        ))}
      </div>
    </section>
  )
}

export default Experience
